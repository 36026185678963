export const STORAGE_KEY = {
  THREADS_PAGINATION: "threadsPagination",
  THREADS_CATEGORIES: "threadsCategories",
  THREADS_SCROLL_POSITION: "threadsScrollPosition",
  THREADS_REPLYING: "threadsReplying",
  NOTIFICATION_PERMISSION: "notificationPermission",
};

export const RXDB_KEY = {
  THREADS: "threads",
  THREADS_MESSAGES: "threadmessages",
};

export const THREADS_COLORS = ["rgb(236 218 244)", "rgb(222 248 248)"];
export const THREADS_COLORS_CATEGORIES_PROD = [
  {
    label: "Meet-ups and Networking",
    id: "65e49dfddaba6729ac0211b8",
    color: "rgb(224, 255, 255)",
  },
  {
    label: "Advice",
    id: "65f0366540a42104e3b77883",
    color: "rgb(214, 224, 255)",
  },
  {
    label: "Collabs and Partnerships",
    id: "65f0374d40a42104e3b77b90",
    color: "rgb(250, 235, 215)",
  },
  {
    label: "Help Requests",
    id: "65f03b4940a42104e3b783b4",
    color: "rgb(227, 214, 255)",
  },
  {
    label: "General",
    id: "65f6e56888ceda812237a0ca",
    color: "rgb(254, 224, 255)",
  },
  {
    label: "Individualized Needs",
    id: "66013b96c9a233f2931452d5",
    color: "rgb(222, 184, 135)",
  },
  {
    label: "Events",
    id: "6601a21efc7075b7b03ecef5",
    color: "rgb(255, 228, 225)",
  },
  {
    label: "Promotions and Offers",
    id: "66fa7ce5abb521d8c6da8393",
    color: "rgb(225, 251, 221)",
  },
];

export const THREADS_COLORS_CATEGORIES_DEV = [
  {
    label: "category1",
    id: "6560b3d83f064b2079947f8b",
    color: "rgb(255, 182, 193)",
  },
  {
    label: "category2",
    id: "6560b3d03f064b2079947f7e",
    color: "rgb(225, 251, 221)",
  },

  {
    label: "General",
    id: "6560b3d03f064b2079947f7e",
    color: "rgb(224, 255, 255)",
  },
  {
    label: "Collabs and Partnerships",
    id: "670e8995784b17f2061fb9f5",
    color: "rgb(214, 224, 255)",
  },
  {
    label: "Promotions and Offers",
    id: "670e89a0784b17f2061fba0e",
    color: "rgb(250, 235, 215)",
  },
  {
    label: "Mentoring Discussions",
    id: "670e89b0784b17f2061fba27",
    color: "rgb(227, 214, 255)",
  },
];

export const API = {
  url: `${process.env.API_URL}/api`,
  socketUrl: process.env.MESSAGING_API,
};

const isProduction = API.url.includes("-production");

export const DEFAULT_THREAD_CATEGORY = isProduction
  ? "65f6e56888ceda812237a0ca"
  : "6560b3d03f064b2079947f7e";

export const THREADS_COLORS_CATEGORIES = isProduction
  ? THREADS_COLORS_CATEGORIES_PROD
  : THREADS_COLORS_CATEGORIES_DEV;
